<template>
  <div class="Page">
    <div class="header payzing" :class="{ 'at-top': atTop }">
      <div class="navHeader container fullWidth">
        <div class="logo-container">
          <el-autocomplete
            popper-class="my-autocomplete"
            v-model="state"
            :fetch-suggestions="querySearch"
            placeholder="Hãy nhập tên game"
            @select="handleSelect"
            class="inputStyle"
            :class="{ 'show-input': showInput }"
            v-if="!atTop"
          >
            <i class="el-icon-search el-input__icon" slot="prefix"> </i>
            <template slot-scope="{ item }">
              <div class="name" @click="clickTo(item)">
                {{ item.game_name }}
              </div>
            </template>
          </el-autocomplete>
        </div>
        <div class="right-header home-page" v-show="!this.$isMobile">
          <!-- <a href="javascript:void(0)">Câu hỏi thường gặp</a>
          <a href="javascript:void(0)">Hướng dẫn nạp tiền</a>
          <a href="javascript:void(0)">Đại lý thẻ Zing</a>
          <a href="javascript:void(0)">Game trên Zalo</a> -->
        </div>
      </div>
    </div>
    <el-menu v-show="$isMobile && showMenu" class="el-menu-vertical-demo menu">
      <el-menu-item index="1">
        <a href="javascript:void(0)">Câu hỏi thường gặp</a>
      </el-menu-item>
      <el-menu-item index="2">
        <a href="javascript:void(0)">Hướng dẫn nạp tiền</a>
      </el-menu-item>
      <el-menu-item index="3">
        <a href="javascript:void(0)">Đại lý thẻ Zing</a>
      </el-menu-item>
      <el-menu-item index="4">
        <a href="javascript:void(0)">Game trên Zalo</a>
      </el-menu-item>
    </el-menu>
    <router-view></router-view>
    <div>
      <Advertisement @show="getShow" />
      <Footer />
    </div>
    <!-- <Loading :show="showLoading" /> -->
    <Loading1 :show="showLoading" :backgroundColor="false" />
  </div>
</template>

<script>
// import Loading from "../components/Loading";
import Loading1 from "../components/Loading1";
import Advertisement from "../components/Advertisement";
import Footer from "../components/Footer";
export default {
  name: "Page",
  components: { Footer, Advertisement, Loading1 },
  props: {},
  data() {
    return {
      atTop: true,
      state: "",
      showLoading: true,
      showInput: true,
      showMenu: false,
      showAdvert: true,
      // allGame: [],
    };
  },
  computed: {
    allGame() {
      return this.$store.state.gameList;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.showLoading = false;
    this.getData();
    if (!this.$isMobile) {
      window.addEventListener("scroll", this.scrolling);
    } else {
      this.showInput = false;
      this.atTop = false;
    }
  },
  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      // let scrollStep = scrollTop - this.oldScrollTop;
      if (scrollTop > 0) {
        this.atTop = false;
      }
      scrollTop >= 300 ? (this.showInput = false) : (this.showInput = true);

      // console.log("header 滚动距离 ", scrollTop);
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      // 判断是否到了最顶部
      if (scrollTop <= 0) {
        this.atTop = true;
      }
    },
    getData() {
      this.$store.dispatch("getGameList");
      // this.allGame = this.$store.state.gameList;
      // setTimeout(() => {
      //   this.showLoading = false;
      // }, 3000);
    },
    querySearch(queryString, cb) {
      var allGame = this.allGame;
      var results = queryString
        ? allGame.filter(this.createFilter(queryString))
        : allGame;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.game_name
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleClick() {
      this.showMenu = !this.showMenu;
    },
    clickTo(e) {
      localStorage.setItem("game_id", e.game_id);
      localStorage.setItem("game_info", JSON.stringify(e));
      this.$router.push({
        path: "/login",
        query: {
          g: e.game_id,
        },
      });
    },
    handleSelect(item) {
      console.log(item);
    },
    getShow(show) {
      this.showAdvert = show;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>

<style scoped lang="scss">
$color-theme: #e26900;
$color-theme-rgb: 226, 105, 0;

.Page {
  .header {
    height: 60px;
    // padding: 0 60px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid rgb(17, 136, 255);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .header,
  .header .navHeader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
  }

  .header .navHeader.fullWidth {
    max-width: unset;
    // margin: auto;
    margin: 0 60px;
  }

  .at-top {
    background-color: transparent;
    border: none;
  }

  .logo-container {
    display: grid;
    grid-template-columns: 140px 1fr;
    grid-gap: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .header img,
  .logo img {
    display: block;
    width: 100px;
  }

  // .shopvnggames img {
  //   width: 130px;
  // }
  .content {
    margin-top: 60px;
  }
  .right-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    margin-right: 10px;
  }

  .right-header a {
    text-decoration: none;
    color: #454545;
    display: inline-block;
    font-size: 16px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: right;
    cursor: pointer;
    font-weight: 700;
  }

  .right-header a:hover {
    opacity: 0.7;
  }

  .at-top .home-page a {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: #fff;
  }

  .at-top .home-page .nav-langSelect .el-input .el-input__prefix div {
    color: #fff;
  }

  .inputStyle {
    width: 300px;
  }
  .el-autocomplete-suggestion.el-popper {
    background: #fff;
    padding: 0;
    li {
      border-bottom: 1px solid hsla(0, 0%, 77.6%, 0.5);
    }
    .el-scrollbar {
      border-radius: 10px;
    }
    .el-autocomplete-suggestion__wrap {
      border: 0;
    }
    .el-autocomplete-suggestion__wrap {
      padding: 0;
    }

    .el-autocomplete-suggestion__wrap .el-autocomplete-suggestion__list li {
      font-weight: 300;
      height: 55px;
      line-height: 55px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      color: #454545;
      padding-left: 34px;
      padding-right: 45px;
    }

    .el-autocomplete-suggestion__wrap
      .el-autocomplete-suggestion__list
      li:last-child {
      border: none;
    }

    .el-autocomplete-suggestion__wrap
      .el-autocomplete-suggestion__list
      li
      span:last-child {
      text-transform: capitalize;
    }
  }
  .show-input {
    display: none;
  }
  .service {
    bottom: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .header {
    height: 55px !important;
    .navHeader.fullWidth {
      margin: 0 10px !important;
    }
  }
  .header img {
    width: 80px !important;
  }

  .Page {
    .inputStyle {
      width: 100%;
    }
    .logo-container {
      display: inline-block;
      width: 100%;
    }
  }

  .mobile-menu {
    display: flex;
    margin-right: 10px;
    .el-menu-item {
      border-bottom: 1px solid hsla(0, 0%, 78%, 0.5);
      a {
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .el-menu-item.is-active {
      color: initial;
    }
    .shape-menu {
      width: 20px;
      height: auto;
      background: none;
      border: 0;
      outline: none;
      padding-top: 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      height: 20px;
      margin-right: 10px;
    }

    .shape-menu span {
      width: 20px;
      height: 2px;
      background: #454545;
      display: block;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }

    .shape-menu.active {
      padding-top: 10px;
    }
    .shape-menu.active span:first-child {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: relative;
      top: 3px;
    }

    .shape-menu.active span:nth-child(2) {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    .shape-menu.active span:nth-child(3) {
      opacity: 0;
    }
    .shape-menu .rightPanel-background {
      opacity: 1;
      background: hsla(0, 0%, 100%, 0.4);
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      position: fixed;
      z-index: -1;
      margin-top: 55px;
    }

    .shape-menu.active .rightPanel-background {
      z-index: 998;
      opacity: 1;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
  .menu {
    z-index: 999;
    position: fixed;
    top: 55px;
    width: 50%;
    height: 100%;
  }
}
</style>
